import React from 'react';
import { ImageList, ImageListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const tileData = [
  {
    img: 'bigRog1.jpeg',
    title: 'bigRog',
    cols: 2,
  },
  {
    img: 'bigRog2.jpeg',
    title: 'bigRog',
    cols: 2,
  },
  {
    img: 'bigRog3.jpeg',
    title: 'bigRog',
    cols: 2,
    featured: true,
  },
  {
    img: 'bigRog4.jpeg',
    title: 'bigRog',
    cols: 2,
  },
  {
    img: 'bigRog5.jpeg',
    title: 'bigRog',
    cols: 1,
  },
  {
    img: 'bigRog6.jpeg',
    title: 'bigRog',
    cols: 2,
    featured: true,
  },
  {
    img: 'bigRog7.jpeg',
    title: 'bigRog',
    cols: 2,
  },
  {
    img: 'bigRog8.png',
    title: 'bigRog',
    cols: 2,
  },
  {
    img: 'bigRog9.jpg',
    title: 'bigRog',
    cols: 2,
    featured: true,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

export default function ImageListing() {
  const classes = useStyles();

  return (
    <ImageList variant="quilted" cols={3} gap={8} className={classes.root}>
      {tileData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            loading='lazy'
            srcSet={`${item.img}?w=161&fit=crop&auto=format 1x,
                ${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
